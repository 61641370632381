.topFixedBanner{
    background-image: url("../image/banner.jpg");
    min-height: 550px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topBannerOverlay{
    position: absolute;
    min-height: 550px;
    width: 100%;
    background: rgb(0, 0, 0,0.7);

}

.topFixedPage{
    background-image: url("../image/page.png");
    min-height: 250px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topPageOverlay{
    position: absolute;
    min-height: 250px;
    width: 100%;
    background: rgba(19, 14, 31, 0.7);

}

.topContentPage{
    margin-top: 10em;

}
.topPageTitle{
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    color: #ffee00;
    font-size: 40px;
}

.summaryBanner{
    background-image: url("../image/summary.jpg");
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.summaryBannerOverlay{
    position: absolute;
    min-height: 400px;
    width: 100%;
    background: rgb(0, 0, 0,0.7);
}

.countNumber{
    color: #ffffff;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 40px;
}
.iconBullent{
    color: #051b35;
    font-size: 16px;
}
.iconProject{
    color: #ffffff;
    font-size: 40px;
}

.countSection{
    margin-top: 20%;
}


.countTitle{
color: #ffd900;
font-family: 'Raleway',sans-serif;
font-size: 22px;
font-weight: 600;
}

.cardTitle{
    color: #000000;
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    font-size: 15px;
}

.projectCard{
    background: #ffffff;
    width: 22em;
    border-radius: 0.6em;
    margin: 1em;
    cursor: pointer;
    box-shadow: 0 13px 25px -5px;
    transition: all ease 200ms;
}

.projectCard:hover{
    transform: scale(1.03);
    box-shadow: 0 13px 30px -5px;
}

.courseImage{
    width: 100%;
}
.courseViewMore{
    color: #051b35;
    font-family: 'Montserrat',sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
}

.cardSubTitle{
    color: #000000;
    font-family: 'Raleway',sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.workCard{
    margin-top: 25%;
    padding: 10px;
    border-radius: 3px;
}

.topContent{
    margin-top: 15em;

}
.topTitle{
    font-family: 'Montserrat',sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 48px;
}
.topSubTitle{
    font-family: 'Raleway';
    font-weight: 600;
    color: #ffffff;
    font-size: 22px;
}

.ecommerceIcom{
    height: 80px;
    width: 80px;
}

.designIcom{
    height: 80px;
    width: 80px;
}

.webIcom{
    height: 80px;
    width: 80px;
}

.serviceCard{
    margin: 5px 5px 5px 5px;
    height: 22rem;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 8px 0 rgba(4, 55, 105, 0.2);
    
}


.serviceCard:hover{
    margin: 5px 5px 5px 5px;
    height: 22rem;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 16px 0 rgba(4, 55, 105, 0.2);
    
}

.serviceName{
    color: #051b35;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.serviceDescription{
    color: #858585;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.serviceMainTitle{
    color: #051b35;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 700;
    font-size: 28px;
}

.bottom{
    background:#051b35 none repeat scroll 0 0;
    height: 3px;
    width: 85px;
    margin: -1.9rem auto 0;
    margin-bottom: 3rem;
}

.navTitle{
    font-family: 'Montserrat','sans-serif';
    color: #ffffff !important;
    font-weight: 400;
    font-size: 30px;
}
.navTitleScroll{
    font-family: 'Montserrat','sans-serif';
    color: #051b35 !important;
    font-weight: 400;
    font-size: 30px;
}
.navBackground{
    transition: background-color 400ms linear;
    background-color: rgb(00, 00, 00,0.0) !important;
}

.navBackgroundScroll{
    -webkit-transition: background-color 400ms linear;
    -moz-transition: background-color 400ms linear;
    -o-transition: background-color 400ms linear;
    transition: background-color 400ms linear;
    background-color: #ffffff !important;
    box-shadow: 0 0 8px 0 rgba(00, 00, 00, 0.3);
}

.navItem{
    text-decoration: none !important;
    font-family: 'Montserrat','sans-serif';
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
}

.navItemScroll{
    text-decoration: none !important;
    font-family: 'Montserrat','sans-serif';
    color: #00000000 !important;
    font-weight: bold;
    font-size: 16px;
}

.videoCard{
    box-shadow: 0 13px 10px -5px;
    padding: 8rem 3rem 8rem 3rem;
    background-color: #051b35;
    margin: 20px auto;
    border-radius: 5px;
}

.videText{
    margin-top: 2%;
}

.sliderBack{
    background-color: #051b35;
    min-height: 400px;
}

.reviewMainTitle{
    color: #ffffff;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 700;
    font-size: 28px;
}

.reviewbottom{
    background:#ffffff none repeat scroll 0 0;
    height: 3px;
    width: 85px;
    margin: -1.9rem auto 0;
    margin-bottom: 3rem;
}

.circleImg{
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid #ffffff;
    border-radius: 50%;
}

.reviewName{
    color: #ffffff;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.reviewDescription{
    color: #ffffff;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.aboutMeImage{
    width: 550px;
    height: 550px;
    background-color: #051b35;
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    float: right;
}

.aboutImg{
    height: 100%;
    margin-top: 210px;
}

.aboutMeBody{
    width: 100%;
    height: 100%;
    padding-left: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10rem;

}

.aboutMeTitle{
    color: #051b35;
    font-family: 'Raleway',sans-serif;
    font-size: 40px;
    font-weight: 700;
}

.aboutMeDetails{
    color: #051b35;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 28px;
    font-weight: 600;

}

.footerSection{
    margin-top: 5rem;
    box-shadow: 0 0 8px 0;
    background-color: #051b35;
}

.footerName{
    color: #ffffff;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.social-container{
    margin-top: 30px;
}

a.social{
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover{
    transform: translateY(-2px);
}

a.facebook{
    color: #ffffff;
}

a.youtube{
    color: #ffffff;
}

a.twitter{
    color: #ffffff;
}

.footerDescription{
    color: #e9e8e8;
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 600;
}
.footerLink{
    text-decoration: none;
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #e9e8e8;
}

.footerLink:hover{
    text-decoration: none;
}

.copyrightSection{
    padding: 20px 20px 20px 20px;
    background-color: #002c61;
}

.copyrightSection a{
    text-decoration: none;
}

.copyrightlink{
    text-decoration: none;
    color: #ffffff;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.copyrightlink:hover{
    text-decoration: none;
    color: #ffffff;
}


/* Home page Welcome Page Design*/
.intro-area-top{
    margin-top: -70px;
    position: relative;
    z-index: 2;
}

.section-title{
    margin-bottom: 45px;
    position: relative;
}

.section-title .sub-title{
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
}

.intro-area-inner{
    box-shadow: 0 0 30px rgb(0 0 0 / 7%);
    background: #fff;
    padding: 50px 0 0;
    border-radius: 8px;
}

.section-title .sub-title.double-line::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 10px;
    height: 2px;
    width: 40px;
    background: #051b35;
}

.section-title .sub-title.double-line::after {
    content: "";
    position: absolute;
    right: -50px;
    top: 10px;
    height: 2px;
    width: 40px;
    background: #051b35;
}

.maintitle{
    color: #051b35;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 40px;
    font-weight: 700;
}
.intro-footer{
    padding: 30px 30px 10px;
    border-radius: 0 0 7px 7px;
}

.bg-base{
    background: #ffd900;
}

.sideImg{
    margin-top: -15px;
}

.homeIntro{
    color: #051b35;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px;
    font-weight: 700;
}

.link-style{
    color: #fff;
    text-decoration: none !important;
}

.link-style:hover{
    color: #fff;
    text-decoration: none !important;
}

.about-thumb-wrap.after-shape{
    margin-right: 30px;
}

.about-thumb-wrap.after-shape:after{
    content: "";
    position: absolute;
    left: 800px;
    width: 104px;
    height: 60%;
    top: 65%;
    border-radius: 10px;
    background: #ffee00;
    z-index: -1;
    transform: translateY(-50%);
}

.project-details{
    margin-left: 35px;
}

.projectDetailsText{
    color: #051b35;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 40px;
    font-weight: 700;
}

.detailsName{
    color: #051b35;
    font-family: 'Raleway',sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.courseDetailsText{
    color: #051b35;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 40px;
    font-weight: 700;

}

.courseDetailsImg{
    width: 100%;
}

.CourseallDescription{
    color: #051b35;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 15px;
    font-weight: 700;
}

.widget_feature{
    border:1px solid #cbd6e2;
}

.widget-title{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 22px;
    font-weight: 700;
    position: relative;
}

.widget_feature ul{
    padding: 0 25px 20px;
}

.widget_feature ul li{
    color: #051b35;
    font-size: 16px;
    margin-bottom: 9px;
    list-style: none;
}

.widget_feature ul li span{
    font-weight: 500;
    color: #051b35;
    margin-right: 5px;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 22px;
    font-weight: 700;
}

.widget_feature .price-wrap {
    background-color: #F0F4F9;
    padding: 34px 23px 40px;
}

.widget_feature .price-wrap .btn {
    width: 100%;
}

.btn-base {
    color: #051b35;
    background: #ffee00;;
}

.LoaderAnimation{
    width: 140px;
    height: 140px;
}

.errorIcon{
    width: 140px;
    height: 140px;
}










