
@media (max-width: 575.98px) { 
    .topTitle{font-size: 30px;}
    .topSubTitle{font-size: 16px;}
    .projectCard{width: 23em;margin-top: 60px;}
    .aboutMeImage{width: 400px;height: 400px;}
}


@media (max-width: 767.98px) { 
    .topTitle{font-size: 38px;}
    .topSubTitle{font-size: 19px;}
}


@media (max-width: 991.98px) {}


@media (max-width: 1199.98px) { }


@media (max-width: 1399.98px) { }

